// src/Button.js
import React, { useEffect } from 'react'
import '../ad/TonAdBanner.css'
import { getExchangeAd } from '../../utils/api'
import {
  GetUserOpenId,
  openUrl,
  SendTonExchangeEvent
} from '../../utils/my_util'
import '../index.css'

interface TonExchangeBannerProps {
  inExchangeCampaignId: string
  outExchangeCampaignId: string
  icon: string
  image: string
  text: string
  destination: {
    actionType: string
    destinationConfig: any
  }
}
export const TonExchangeBanner = ({
  exchangeId,
  style
}: {
  exchangeId: string
  style?: React.CSSProperties
}) => {
  // const [isRender, setIsRender] = React.useState(false)
  // useEffect(() => {
  //   setIsRender(true)
  // }, [])

  // if (!isRender) return <></>
  const [ad, setAd] = React.useState<TonExchangeBannerProps>()
  useEffect(() => {
    // console.log('TonExchangeBannerProps ad', ad)
    if (!ad) return
    SendTonExchangeEvent('impression', ad)
  }, [ad])

  useEffect(() => {
    // TODO: fetch ad
    // fetchAd(exchangeId,tgUserId)
    // console.log('fetch ad', exchangeId)
    getExchangeAd({
      exchangeCampaignId: exchangeId,
      source: 'normal',
      viewer: { telegramUserId: GetUserOpenId() || '' }
    })
      .then((data) => {
        // console.log('fetch ad', data)
        if (data.success === true) {
          setAd(data)
        }
      })
      .catch((e) => {
        console.error('fetch ad', e)
      })
  }, [exchangeId])

  const clickAd = (ad: TonExchangeBannerProps) => {
    SendTonExchangeEvent('click', ad)
    openUrl(ad.destination)
  }
  return (
    <div
      style={{
        display: ad ? 'flex' : 'none'
      }}
    >
      {ad ? (
        <div className={'container'} style={{ ...style! }}>
          <div
            className={'aspect-ratio-box'}
            onClick={() => {
              clickAd(ad)
            }}
          >
            <img src={ad.image} alt={ad.text} />
            {/* <span>{ad.text}</span> */}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default TonExchangeBanner
