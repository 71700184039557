// src/Button.js
import React, { useEffect } from 'react'
import '../index.css'
import { GetTonAd, openUrl, SendTonAdEvent } from '../../utils/my_util'

interface TonAdBannerProps {
  adId: string
  adBlockId: string
  campaignId: string
  image: string
  text: string
  destination: {
    actionType: string
    destinationConfig: any
  }
}
export const TonAdBanner = ({
  blockId,
  style
}: {
  blockId: string
  style?: React.CSSProperties
}) => {
  // const [isRender, setIsRender] = React.useState(false)
  // useEffect(() => {
  //   setIsRender(true)
  // }, [])

  // if (!isRender) return null

  const [ad, setAd] = React.useState<TonAdBannerProps>()
  useEffect(() => {
    if (!ad) return
    SendTonAdEvent('impression', ad)
  }, [ad])

  const getAd = async (blockId: string) => {
    try {
      const data = await GetTonAd(blockId)
      // console.log('fetch banner ad', data)
      if (data.success) {
        setAd({
          adId: data?.data?.adId,
          adBlockId: data?.data?.adBlockId,
          campaignId: data?.data?.campaignId,
          image: data?.data?.image,
          text: data?.data?.text,
          destination: data?.data?.destination
        })
      }
    } catch (error) {
      console.error('fetch ad', error)
    }
  }

  useEffect(() => {
    if (blockId) {
      getAd(blockId)
    }
  }, [blockId])

  const clickAd = (ad: TonAdBannerProps) => {
    SendTonAdEvent('click', ad)
    openUrl(ad.destination)
  }
  return (
    <div
      style={{
        display: ad ? 'flex' : 'none'
      }}
    >
      {ad ? (
        <div className={'container'} style={{ ...style! }}>
          <div
            className={'aspect-ratio-box'}
            onClick={() => {
              clickAd(ad)
            }}
          >
            <img src={ad.image} alt={ad.text} />
            {/* <span>{ad.text}</span> */}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default TonAdBanner
