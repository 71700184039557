/**
 * 监听 window.location.href 直到包含 '#tgWebAppData='，然后调用 decodeTgWebAppDataFromUrl 函数。
 */
export function monitorTgWebAppData() {
  if (!window) {
    console.log('window is not defined')
    return
  }
  // 定义处理 hash 变化的函数
  function handleHashChange() {
    const currentHref = window?.location?.href
    // console.log(`currentHref: ${currentHref}`) // 调试用
    if (currentHref.includes('#tgWebAppData=')) {
      decodeTgWebAppDataFromUrl() // 调用目标函数
      // 移除事件监听器，停止监听
      window?.removeEventListener('hashchange', handleHashChange)
      // console.log('call decodeTgWebAppDataFromUrl, remove hashchange monitor') // 调试用
    }
  }
  // 初始检查是否已经包含目标哈希
  if (window?.location?.href.includes('#tgWebAppData=')) {
    decodeTgWebAppDataFromUrl()
    // console.log('Pass check,call decodeTgWebAppDataFromUrl') // 调试用
  } else {
    // 添加 hashchange 事件监听器
    window?.addEventListener('hashchange', handleHashChange)
    // console.log('add hashchange monitor') // 调试用
  }
}
// 解析 tgWebAppData 中的 tgWebData window: Window
export const decodeTgWebAppDataFromUrl = () => {
  if (!window) {
    console.log('window is not defined')
    return null
  }
  const urlString = window?.location?.href || ''
  const splitUrl = urlString.split('#tgWebAppData=')
  if (splitUrl.length > 1) {
    // 处理 tgWebAppData 参数
    const tgWebAppDataObject = handleUrlParamHashData(splitUrl[1])
    if (tgWebAppDataObject && (tgWebAppDataObject as any)?.user?.id) {
      // localStorage?.setItem('tgWebAppData', JSON.stringify(tgWebAppDataObject))
      localStorage?.setItem(
        'TON_AI_TG_APP_DATA',
        JSON.stringify(tgWebAppDataObject)
      )
      if (
        (tgWebAppDataObject as any)?.user?.id !==
        localStorage?.getItem('TON_AI_OPEN_USER_ID')
      ) {
        localStorage?.setItem(
          'TON_AI_OPEN_USER_ID',
          (tgWebAppDataObject as any)?.user?.id?.toString()
        )
      }
      return tgWebAppDataObject
    }
  }
  return null
}

const handleUrlParamHashData = (hashData: string) => {
  let jsonResult = {}
  const hashParams = new URLSearchParams(decodeURIComponent(hashData))
  // 创建一个空对象用于存储转换结果
  hashParams.forEach((value, key, parent) => {
    // console.log('handleUrlParamHashData', { key, value, parent })
    if (key === 'tgWebAppData') {
      jsonResult = { ...jsonResult, [key]: handleUrlParamHashData(value) }
    } else {
      try {
        jsonResult = { ...jsonResult, [key]: JSON.parse(value) }
      } catch (error) {
        jsonResult = { ...jsonResult, [key]: value }
      }
    }
  })
  // console.log({ hashData, jsonResult })
  return jsonResult
}
// 获取 tgWebAppData
export const getTgWebAppData = () => {
  if (!window) {
    console.log('window is not defined')
    return
  }
  const tgWebAppData = localStorage?.getItem('TON_AI_TG_APP_DATA')
  if (tgWebAppData) {
    return JSON.parse(tgWebAppData)
  }
  return decodeTgWebAppDataFromUrl()
}

/**
 * 监听 window.location.search 直到包含 'tgWebAppStartParam' 参数，
 * 然后将其存储到 localStorage
 */
export function monitorTgWebAppStartParam() {
  if (!window) {
    console.log('window is not defined')
    return
  }
  // 定义处理 URL 变化的函数
  function handleUrlChange() {
    const searchParams = new URLSearchParams(window?.location?.search)
    const startParam = searchParams.get('tgWebAppStartParam')

    if (startParam) {
      // 存储到 localStorage
      localStorage?.setItem('TON_AI_TG_START_PARAM', startParam)
      // 移除事件监听器，停止监听
      window?.removeEventListener('popstate', handleUrlChange)
      // console.log('saved tgWebAppStartParam, remove popstate monitor') // 调试用
    }
  }

  // 初始检查是否已经包含目标参数
  const searchParams = new URLSearchParams(window?.location?.search)
  const startParam = searchParams.get('tgWebAppStartParam')

  if (startParam) {
    localStorage?.setItem('TON_AI_TG_START_PARAM', startParam)
    // console.log('Pass check, saved tgWebAppStartParam') // 调试用
  } else {
    // 添加 popstate 事件监听器
    window?.addEventListener('popstate', handleUrlChange)
    // console.log('add popstate monitor') // 调试用
  }
}

// 获取 tgWebAppStartParam
export const getTgWebAppStartParam = () => {
  if (!window) {
    console.log('window is not defined')
    return null
  }
  return localStorage?.getItem('TON_AI_TG_START_PARAM')
}
