// telegramWebAppListener.ts

/**
 * 检查对象路径是否存在
 * @param obj 要检查的对象
 * @param path 对象路径，例如 "Telegram.WebApp"
 * @returns boolean
 */
function hasNestedProperty(obj: any, path: string): boolean {
  return path.split('.').every((key) => {
    if (obj && typeof obj === 'object' && key in obj) {
      obj = obj[key]
      return true
    }
    return false
  })
}

/**
 * 监听 window.Telegram.WebApp 的出现，并在出现时调用回调函数
 * @param callback 当 Telegram WebApp 可用时调用的函数
 * @param interval 轮询间隔（毫秒）
 * @param timeout 最大等待时间（毫秒），避免无限等待
 */
export function listenForTelegramWebApp(
  callback: (data: any) => void,
  onTimeout?: () => void,
  interval: number = 500,
  timeout: number = 5000
): void {
  if (!window || typeof window === 'undefined') {
    // 在 SSR 环境中不执行任何操作
    return
  }

  let observer: MutationObserver | null = null
  let polling: NodeJS.Timeout | null = null
  let elapsed: number = 0

  // 定义要检测的路径
  const targetPath = 'Telegram.WebApp'

  const cleanup = () => {
    if (observer) {
      observer.disconnect()
      observer = null
    }
    if (polling) {
      clearInterval(polling)
      polling = null
    }
  }

  const checkAndCallback = () => {
    if (hasNestedProperty(window, targetPath)) {
      callback((window as any)?.Telegram?.WebApp?.initDataUnsafe)
      cleanup()
    } else if (elapsed >= timeout) {
      // 超时处理（可选）
      console.warn(`Listen Telegram.WebApp timeout:（${timeout}ms）`)
      onTimeout?.()
      cleanup()
    }
  }

  // 初始检查
  checkAndCallback()

  if (hasNestedProperty(window, targetPath)) {
    // 如果已存在，已调用回调，无需设置监听
    return
  }

  // 使用 MutationObserver 监听 DOM 变化
  observer = new MutationObserver(() => {
    checkAndCallback()
  })

  // 监听整个文档的变化
  observer.observe(document, { childList: true, subtree: true })

  // 同时使用轮询作为备选
  polling = setInterval(() => {
    elapsed += interval
    checkAndCallback()
  }, interval)
}
