import axios from 'axios'
import { TonAdProps } from './models'
import { signRequest } from './my_util'
const BASE_URL = 'https://app.ton.ai' // 替换为实际的后端 API 地址

// POST
const postData = async <T>(path: string, params?: any) => {
  const { data } = await instance().post(path, params)
  if (data.code !== 0) {
    getError(data)
    if (data.code === -999) {
      throw new Error(data.code + '')
    } else {
      throw new Error(data.message)
    }
    // return { code: data.code, data: data?.message || 'Error!' }
  }
  return data.data as T
}
// GET
const getData = async <T>(path: string, params?: any) => {
  const { data } = await instance().get(path, { params: params })
  if (data.code !== 0) {
    getError(data)
    if (data.code === -999) {
      throw new Error(data.code + '')
    } else {
      throw new Error(data.message)
    }
    // return { code: data.code, data: data?.message || 'Error!' }
  }
  return data.data as T
}
const getError = (data: {
  code: 1 | 0 | -1 | 401 | 403 | 404 | 500
  message: string
}) => {
  console.log('getError\n', data)
  if (data.code === 401) {
    // removeLocalStorage('token')
    // clearLocalStorage()
  } else {
    console.log('getError\n', data)
  }
}

const instance = () => {
  // console.log('request:', process.env.NEXT_PUBLIC_API, getUserToken())
  let baseURL = BASE_URL
  if (localStorage.getItem('TON_AI_BASE_URL')) {
    baseURL = localStorage.getItem('TON_AI_BASE_URL') as string
  }
  const headers = {
    'access-control-allow-origin': '*',
    accept: 'application/json'
    // 'x-api-key': localStorage.getItem('TON_AI_APP_KEY')
  }
  const axiosInstance = axios.create({
    baseURL: baseURL + '/api',
    headers
  })
  // 添加一个请求拦截器
  axiosInstance.interceptors.request.use(
    function (config) {
      config.headers['api-request-type'] = 'sdk'
      const apiKey = localStorage.getItem('TON_AI_APP_KEY')
      if (apiKey) {
        config.headers['x-api-key'] = apiKey
        config.headers['apikey'] = apiKey
      }
      const apiId = localStorage.getItem('TON_AI_APP_ID')
      if (apiId) {
        config.headers['x-api-id'] = apiId
      }
      const debug = localStorage.getItem('TON_AI_DEBUG') === 'true'
      if (debug) {
        config.headers['debug'] = debug
      }
      //获取当前 sdk 的版本号
      const version = __TON_AI_SDK_VERSION__ || '1.0.0'
      if (version) {
        config.headers['sdk-version'] = 'V' + version
      }
      return config
    },
    function (error) {
      console.log('axios.interceptors.request error\n', error)
      return Promise.reject(error)
    }
  )
  const maxRetries = 2
  // 添加一个响应拦截器
  axiosInstance.interceptors.response.use(
    function (response) {
      // 对响应数据做点什么
      return response
    },
    async function (error) {
      console.log('axios.interceptors.response error\n', error)
      const { config, response } = error
      // 如果没有配置，直接拒绝
      if (!config) {
        return Promise.reject(error)
      }

      // 初始化重试次数
      config.__retryCount = config.__retryCount || 0

      // 判断是否应该重试
      if (config.__retryCount >= maxRetries || !shouldRetry(error)) {
        // 对响应错误做点什么
        if (error.response) {
          getError(error.response.data)
        } else if (error.request) {
          /* 请求已发送但没有回应*/
        } else {
          /* 发送请求时出错，是设置或其他问题导致的问题 */
        }
        return Promise.reject(error)
      }

      // 增加重试次数
      config.__retryCount += 1
      // 重试延迟，指数退避
      const retryDelay = Math.pow(2, config.__retryCount) * 1000 // 1s, 2s
      console.warn(`请求失败，正在重试... 重试次数：${config.__retryCount}`)
      await wait(retryDelay)

      // 重新发起请求
      return axiosInstance(config)
    }
  )

  return axiosInstance
}

/**
 * 判断是否应该重试请求
 * @param {object} error - axios 错误对象
 * @returns {boolean}
 */
const shouldRetry = (error: {
  code: string
  response: { status: any }
}): boolean => {
  if (error.code === 'ECONNABORTED' || !error.response) {
    return true
  }
  const status = error.response.status
  return status >= 500 || status === 429 || status === 401
}

/**
 * 延迟函数
 * @param {number} ms - 延迟时间（毫秒）
 * @returns {Promise}
 */
const wait = (ms: number): Promise<any> =>
  new Promise((resolve) => setTimeout(resolve, ms))

export const getAd = (params: {
  adBlockId: string
  source?: 'normal' | 'earn'
  viewer: { telegramUserId: string }
}) => {
  if (!params.source) {
    params.source = 'earn'
  }
  return postData<any>('/v2/matching/ad/campaign', params)
}
export const getMultiAd = (params: {
  adBlockId: string
  limit: number
  source?: 'normal' | 'earn'
  viewer: { telegramUserId: string }
}) => {
  if (!params.limit) {
    params.limit = 1
  }
  if (!params.source) {
    params.source = 'earn'
  }
  return postData<{
    success: boolean
    message: string
    msg: string
    ads: TonAdProps[]
  }>('/v2/matching/ad/campaign/multi', params)
}

export const getExchangeAd = (params: {
  exchangeCampaignId: string
  source?: 'normal' | 'earn'
  viewer: { telegramUserId: string }
}) => {
  if (!params.source) {
    params.source = 'earn'
  }
  return postData<any>('/v2/matching/exchange/campaign/new', params)
}
export const getMultiExchangeAd = (params: {
  exchangeCampaignId: string
  source?: 'normal' | 'earn'
  limit: number
  viewer: { telegramUserId: string }
}) => {
  if (!params.source) {
    params.source = 'earn'
  }
  if (!params.limit) {
    params.limit = 1
  }
  return postData<any>('/v2/matching/exchange/campaign/multi/new', params)
}

export const getPlaza = () => {
  return postData<any>(`/v1/campaign/event/plaza`)
}
// 上报用户信息， 获取 unionId
export const postUserProfile = (params: {
  deviceId: string
  telegramUserId: string
  walletAddress?: string
  walletClassify?: string
}) => {
  return postData<any>(`/v2/sdk/user/profile`, params)
}

export const postExchangeEvent = (params: {
  eventType: 'click' | 'impression' | 'conversion' | 'complete'
  eventData: {
    outExchangeCampaignId?: string
    inExchangeCampaignId?: string
    exchangeCampaignId?: string
    telegramUserId?: string
    source?: 'user' | 'system' | 'normal' | 'event' | 'earn'
  }
}) => {
  if (!params.eventData.source) {
    params.eventData.source = 'user'
  }

  const timestamp = Date.now()
  let clearParams = {
    eventType: params?.eventType,
    eventData: {
      ...params?.eventData,
      timestamp
    }
  }
  const sign = signRequest(clearParams)
  clearParams['sign'] = sign
  return postData<any>(`/v2/exchange/report/event/new`, clearParams)
}
// click: campaignId + adId + adBlockId+ telegramUserId
// impression: campaignId + adId + adBlockId+ telegramUserId
// conversion: campaignId + telegramUserId
export const postAdEvent = (params: {
  eventType: 'click' | 'impression' | 'conversion' | 'complete'
  eventData: {
    campaignId?: string
    adId?: string
    adBlockId?: string
    telegramUserId?: string
    num?: number
  }
}) => {
  const timestamp = Date.now()
  let clearParams = {
    eventType: params?.eventType,
    eventData: {
      campaignId: params?.eventData?.campaignId,
      adId: params?.eventData?.adId,
      adBlockId: params?.eventData?.adBlockId,
      telegramUserId: params?.eventData?.telegramUserId,
      timestamp
    }
  }
  if (params?.eventData?.num) {
    clearParams['eventData']['num'] = params?.eventData?.num
  }
  const sign = signRequest(clearParams)
  clearParams['sign'] = sign
  return postData<any>(`/v2/ad/report/event`, clearParams)
}

// 添加新的事件上报接口
export const postCommonEvent = (params: {
  eventName: string
  openId: string
  type: 'telegram'
  eventSource?: 'sdk' | 'api' | 'web'
}) => {
  if (!params.eventSource) {
    params.eventSource = 'sdk'
  }
  return postData<any>('/v2/common/event/report', params)
}

// 验证换量广告是否完成
export const verifyExchangeAd = (params: {
  exchangeCampaignId: string
  openId: string
}) => {
  return getData<any>('/v2/exchange/verify/conversion', params)
}
// 验证广告是否完成
export const verifyAd = (params: { campaignId: string; openId: string }) => {
  return getData<any>('/v2/ad/verify/conversion', params)
}
