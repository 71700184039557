// src/utils/showTonAdPopup.tsx
import React from 'react'
import ReactDOM from 'react-dom'
import '../index.css'
import {
  TonExchangeAdProps,
  TonExchangeAdPopupShowOptions
} from '../../utils/models'
import TonExchangeAdPopup from './TonExchangeAdPopup'

export const TonExchangeAdPopupShow = (
  options: TonExchangeAdPopupShowOptions
): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (typeof window === 'undefined' || typeof document === 'undefined') {
      // reject(new Error('Please run in browser environment!'))
      options?.onAdError?.('Please run in browser environment!')
      return
    }
    let root: any
    // 创建一个包含广告组件的容器 div
    const container = document.createElement('div')
    container.id = `ton-ad-popup`
    container.style.position = 'fixed'
    container.style.top = '0'
    container.style.left = '0'
    container.style.width = '100vw'
    container.style.height = '100vh'
    container.style.display = 'flex'
    container.style.justifyContent = 'center'
    container.style.alignItems = 'center'
    container.style.backgroundColor = 'rgba(0, 0, 0, 0.5)'
    container.style.zIndex = '1000' // 确保广告在最上层

    // 定义回调函数
    const handleAdShow = (ad: TonExchangeAdProps) => {
      options.onAdShow?.(ad)
      // 将容器添加到 body
      document.body.appendChild(container)
    }

    const handleAdClick = (ad: TonExchangeAdProps) => {
      options.onAdClick?.(ad)
      cleanup()
    }

    const handleAdClose = () => {
      cleanup()
      options.onAdClose?.()
      resolve()
    }

    const handleAdComplete = (ad: TonExchangeAdProps) => {
      options.onAdComplete?.(ad)
    }

    const handleAdError = (error: any) => {
      options.onAdError?.(error)
      // cleanup()
      // reject(error)
    }

    // 定义清理函数
    const cleanup = () => {
      if (root) {
        root.unmount()
      } else {
        ReactDOM.unmountComponentAtNode(container)
      }

      if (container.parentNode) {
        container.parentNode.removeChild(container)
      }
    }
    // 兼容 React 18 的 createRoot API
    if ('createRoot' in ReactDOM) {
      // React 18
      console.log('React 18')
      const root = (ReactDOM as any).createRoot(container)
      root.render(
        <TonExchangeAdPopup
          exchangeAd={options.exchangeAd}
          exchangeId={options.exchangeId}
          countdown={options.countdown}
          autoClose={options.autoClose}
          onAdComplete={handleAdComplete}
          onAdShow={handleAdShow}
          onAdClick={handleAdClick}
          onAdError={handleAdError}
          onAdClose={handleAdClose}
          onAdVerifyed={options.onAdVerifyed}
        />
      )
      return
    }

    ReactDOM.render(
      <TonExchangeAdPopup
        exchangeAd={options.exchangeAd}
        exchangeId={options.exchangeId}
        countdown={options.countdown}
        autoClose={options.autoClose}
        onAdComplete={handleAdComplete}
        onAdShow={handleAdShow}
        onAdClick={handleAdClick}
        onAdError={handleAdError}
        onAdClose={handleAdClose}
        onAdVerifyed={options.onAdVerifyed}
      />,
      container
    )
  })
}
